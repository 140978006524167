import styled, { css } from "styled-components"
import Grid from "styled-components-grid"

import { cellGutter } from "./Grid"

const GutteredColumn = styled(Grid.Unit)<{ expandPrint?: boolean }>`
  padding-left: ${cellGutter};
  padding-right: ${cellGutter};
  ${({ expandPrint }) =>
    expandPrint &&
    css`
      @media print {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }
    `}
`

export default GutteredColumn
