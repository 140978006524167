import { Children, isValidElement, ReactNode } from "react"
import tw from "twin.macro"

import useLFL from "lib/useLFL"

import AudioPlayer from "components/AudioPlayer"
import StorySnippet from "components/StorySnippet"

const RE_LANGUAGE = /^\/?lflp\/(\w+)\/(\d+)(\?audio)?/i

enum LFLContentTypes {
  WORDS = "words",
  STORIES = "stories",
}

const capitalise = (string: string | ReactNode) => {
  if (isValidElement(string)) {
    // FIXME: this is jank
    return Children.map(string.props.children, (child, idx) => {
      return (
        (idx === 0 && isValidElement(child) && <u>{capitalise(child)}</u>) ||
        (idx === 0 && capitalise(child)) ||
        child
      )
    })
  }
  if (!string || typeof string !== "string") {
    return string
  }
  const firstLetter = string[0]
  const rest = string?.slice(1)
  return [firstLetter.toUpperCase(), rest].join("")
}

export const LFLRenderer = ({
  id,
  type = LFLContentTypes.WORDS,
  audio = false,
  children,
}) => {
  const { replacedText, ...lflData } = useLFL(id, type)

  const innerText = children?.[0]?.props?.children

  const isCapitalised =
    typeof innerText === "string" && innerText[0].toUpperCase() === innerText[0]

  const content = replacedText || lflData?.markedup_text || children

  switch (type) {
    case LFLContentTypes.STORIES:
      return lflData ? (
        <StorySnippet {...lflData} tw="float-right ml-7" />
      ) : null
    case LFLContentTypes.WORDS:
    default:
      return (
        <span
          lang="bck"
          title={
            lflData
              ? `"${lflData?.markedup_text}" means ${lflData?.english}`
              : null
          }
        >
          {isCapitalised ? capitalise(content) : content}
          {audio && lflData?.audio && (
            <div tw="inline-block ml-1">
              <AudioPlayer
                src={lflData.audio}
                track={`/api/words/${id}/bck.vtt`}
                title={`Hear "${lflData.markedup_text}" pronunciation`}
              />
            </div>
          )}
        </span>
      )
  }
}

export const MDLanguageRenderer = ({
  href,
  children,
}: {
  href: string
  children: ReactNode
}) => {
  if (!href.match(RE_LANGUAGE)) {
    const isExternal = href.startsWith("http")
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={href}
        tw="text-primary font-bold"
        target={isExternal ? "_blank" : undefined}
        rel={isExternal ? "noopener" : undefined}
      >
        {children}
      </a>
    )
  }
  const [, type, id, audioFlag] = href.match(RE_LANGUAGE)
  return (
    <LFLRenderer type={type as LFLContentTypes} id={id} audio={!!audioFlag}>
      {children}
    </LFLRenderer>
  )
}

export default MDLanguageRenderer
