import tw from "twin.macro"
import Container from "components/Container"
import Grid from "components/Grid"
import Column from "components/Column"

const Section = ({
  anchor = null,
  padding = tw`py-7.5`,
  gridSize = { lg: 2 / 3 },
  children,
}) => {
  return (
    <section id={anchor} css={padding}>
      <Container>
        <Grid halign="center">
          <Column size={gridSize}>{children}</Column>
        </Grid>
      </Container>
    </section>
  )
}

export default Section
