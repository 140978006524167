export const PlayIcon = (props) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2002 7L0.200195 13.9282L0.200195 0.0717964L12.2002 7Z"
      fill="currentColor"
    />
  </svg>
)

export default PlayIcon
