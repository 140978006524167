export const AudioIcon = (props) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.46154 1.40039L3.87179 4.60039H1V9.40039H3.87179L7.46154 12.6004V1.40039Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7207 4.16797C11.3937 4.91808 11.7717 5.93531 11.7717 6.99597C11.7717 8.05663 11.3937 9.07386 10.7207 9.82397"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8457 1C14.2248 2.59147 14.9995 4.74967 14.9995 7C14.9995 9.25033 14.2248 11.4085 12.8457 13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AudioIcon
