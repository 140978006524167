import { useState, useEffect, useMemo } from "react"
import handleError from "lib/handleError"

export enum LFLContentTypes {
  WORDS = "words",
  STORIES = "stories",
}

const replacements = [
  {
    original: "Barrangga",
    markup: (
      <>
        Barra<u>ng</u>ga
      </>
    ),
  },
  {
    original: "Winamu",
    markup: (
      <>
        Win<u>a</u>mu
      </>
    ),
  },
  {
    original: "Galanganyja",
    markup: (
      <>
        Gala<u>ng</u>anyja
      </>
    ),
  },
  {
    original: "Walnga",
    markup: (
      <>
        Wal<u>ng</u>a
      </>
    ),
  },
  {
    original: "Nyurrngu",
    markup: (
      <>
        Nyurr<u>ng</u>u
      </>
    ),
  },
  {
    original: "Wunggurrunba",
    markup: (
      <>
        Wu<u>ng</u>gurrunba
      </>
    ),
  },
  {
    original: "Jarringgi",
    markup: (
      <>
        Jarri<u>ng</u>gi
      </>
    ),
  },
  {
    original: "Gananganyja",
    markup: (
      <>
        Ga<u>n</u>a<u>ng</u>anyja
      </>
    ),
  },
  {
    original: "Danggu",
    markup: (
      <>
        Da<u>ng</u>gu
      </>
    ),
  },
  {
    original: "Bandaralngarri",
    markup: (
      <>
        Bandaral<u>ng</u>arri
      </>
    ),
  },
  {
    original: "Bandilngan",
    markup: (
      <>
        Bandil<u>ng</u>an
      </>
    ),
  },
  {
    original: "jalangurru",
    markup: (
      <>
        jala<u>ng</u>urru
      </>
    ),
  },
  {
    original: "jangala",
    markup: (
      <>
        ja<u>ng</u>ala
      </>
    ),
  },
  {
    original: "janggurru",
    markup: (
      <>
        ja<u>ng</u>gurru
      </>
    ),
  },
  {
    original: "jawangari",
    markup: (
      <>
        jawa<u>ng</u>ari
      </>
    ),
  },
  {
    original: "jungurra",
    markup: (
      <>
        ju<u>ng</u>urra
      </>
    ),
  },
  {
    original: "maningarri",
    markup: (
      <>
        mani<u>ng</u>arri
      </>
    ),
  },
  {
    original: "Mulunggu",
    markup: (
      <>
        Mulu<u>ng</u>gu
      </>
    ),
  },
  {
    original: "nawangari",
    markup: (
      <>
        nawa<u>ng</u>ari
      </>
    ),
  },
  {
    original: "ngaja",
    markup: (
      <>
        <u>ng</u>aja
      </>
    ),
  },
  {
    original: "ngala",
    markup: (
      <>
        <u>ng</u>ala
      </>
    ),
  },
  {
    original: "ngalgurru",
    markup: (
      <>
        <u>ng</u>algurru
      </>
    ),
  },
  {
    original: "ngarra",
    markup: (
      <>
        <u>ng</u>arra
      </>
    ),
  },
  {
    original: "ngawgi",
    markup: (
      <>
        <u>ng</u>awgi
      </>
    ),
  },
  {
    original: "ngawi",
    markup: (
      <>
        <u>ng</u>awi
      </>
    ),
  },
  {
    original: "ngawngu",
    markup: (
      <>
        ngaw<u>ng</u>u
      </>
    ),
  },
  {
    original: "Thalalngi",
    markup: (
      <>
        Thalal<u>ng</u>i
      </>
    ),
  },
  {
    original: "walnga",
    markup: (
      <>
        wal<u>ng</u>a
      </>
    ),
  },
  {
    original: "Warangarri",
    markup: (
      <>
        Wara<u>ng</u>arri
      </>
    ),
  },
  {
    original: "ngarranggani",
    markup: (
      <>
        <u>ng</u>arra<u>ng</u>gani
      </>
    ),
  },
]

export async function getLFLData(type: LFLContentTypes, id: string | number) {
  try {
    const response = await fetch(`/api/${type}/${id}`)
    const data = response.json()
    return data
  } catch (e) {
    handleError(`Error fetching language data: ${type}/${id}`, e)
    return null
  }
}

export default function useLFL(
  id: string | number,
  type = LFLContentTypes.WORDS
) {
  const [LFLData, setLFLData] = useState(null)
  const replacedText = useMemo(
    () =>
      replacements.find(({ original }) => {
        return original === LFLData?.markedup_text
      })?.markup || LFLData?.markedup_text,
    [LFLData?.markedup_text]
  )
  useEffect(() => {
    if (!id) {
      return
    }
    setLFLData(null)
    getLFLData(type, id).then((d) => setLFLData(d))
  }, [id, type])

  return { ...LFLData, replacedText }
}
