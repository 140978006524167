import styled from "styled-components"
import StyledGrid from "styled-components-grid"

export const cellGutter = "10px"

export const Grid = styled(StyledGrid).attrs(() => ({
  wrap: true,
}))`
  margin-left: -${cellGutter};
  margin-right: -${cellGutter};
`

export default Grid
