/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState } from "react"
import tw from "twin.macro"
import Link from "next/link"

import { H5, H6 } from "styles/Text"
import SRText from "components/SRText"
import PlayIcon from "icons/Play"

export const StorySnippet = ({
  cover_image_thumb,
  title,
  title_english,
  sections,
  id,
  cover_image_full,
  ...props
}) => {
  const audio = sections?.[0]?.audio
  const audioEl = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const togglePlay = () => {
    if (isPlaying) {
      audioEl.current.pause()
      audioEl.current.currentTime = 0
      setIsPlaying(false)
    } else {
      audioEl.current.play()
    }
  }
  return (
    <div {...props}>
      <div tw="w-56">
        <div tw="w-full shadow-2xl rounded-2xl overflow-hidden relative">
          <Link href={`/story/${id}`} passHref>
            <a tw="absolute inset-0">
              <SRText>
                Story {title_english}: {title}
              </SRText>
            </a>
          </Link>
          <img
            src={cover_image_thumb}
            alt={`${title}: ${title_english}`}
            tw="w-full object-cover block"
            css={{
              height: "246px",
            }}
          />
          <div tw="py-5 px-3 flex items-end">
            <div tw="flex-grow">
              <H6 tw="text-dark-grey mb-1" as="div">
                Story
              </H6>
              <H5 as="h3" tw="text-black">
                {title_english}: {title}
              </H5>
            </div>
            {audio && (
              <div tw="flex-shrink-0">
                <button
                  type="button"
                  tw="-mr-1 -mb-3 bg-primary rounded-full w-10 h-10 cursor-pointer flex items-center justify-center relative z-10 text-black"
                  onClick={togglePlay}
                >
                  <SRText>
                    {isPlaying ? "Stop" : "Play"} {title} Snippet
                  </SRText>
                  {isPlaying ? <div tw="w-3 h-3 bg-current" /> : <PlayIcon />}
                </button>
              </div>
            )}
          </div>
          {audio && (
            <audio
              ref={audioEl}
              onPlay={() => setIsPlaying(true)}
              onEnded={() => setIsPlaying(false)}
            >
              <source src={audio} />
              <track
                lang="bck"
                kind="captions"
                src={`/api/stories/${id}/${sections?.[0]?.id}/bck.vtt`}
              />
            </audio>
          )}
        </div>
        {audio && (
          <button
            type="button"
            tw="text-center w-full py-2.5 mt-5 text-base text-primary font-semibold cursor-pointer"
            tabIndex={-1}
            aria-hidden="true"
            onClick={togglePlay}
          >
            {isPlaying ? "Playing..." : "Listen to sample"}
          </button>
        )}
      </div>
    </div>
  )
}

export default StorySnippet
