import tw from "twin.macro"
import { useState, useRef } from "react"
import AudioIcon from "icons/Audio"
import SRText from "./SRText"

export const AudioPlayer = ({
  src,
  track,
  onClick = null,
  active = null,
  ...props
}) => {
  const audio = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlay = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (isPlaying) {
      audio.current.pause()
      audio.current.currentTime = 0
      setIsPlaying(false)
    } else {
      audio.current.play()
    }
  }
  return (
    <>
      <button
        tw="w-8 h-8 cursor-pointer inline-flex items-center justify-center rounded-full flex-shrink-0"
        type="button"
        css={
          isPlaying || active
            ? tw`bg-black text-primary`
            : tw`bg-primary text-black`
        }
        aria-controls={`audio-player-${src}`}
        onClick={onClick || togglePlay}
        {...props}
      >
        <SRText>{isPlaying ? "Stop" : "Play"} audio</SRText>
        <AudioIcon />
      </button>
      <audio
        id={`audio-player-${src}`}
        ref={audio}
        onPlay={() => setIsPlaying(true)}
        onEnded={() => setIsPlaying(false)}
      >
        <source src={src} />
        <track kind="captions" src={track} />
      </audio>
    </>
  )
}

export default AudioPlayer
