import styled from "styled-components"
import { fontBase, breakpoint } from "styles/Variables"

export const CONTAINER_SIZE = 1260

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: ${CONTAINER_SIZE / fontBase}rem;
  max-width: 100%;
  @media screen {
    padding-left: 20px;
    padding-right: 20px;
  }
  ${breakpoint.lg`
    padding-left: 30px;
    padding-right: 30px;
  `}
`

export default Container
