import styled from "styled-components"
import tw from "twin.macro"
import MD from "react-markdown"

import LFLRenderer from "lib/remark-lfl-renderer"
import { BodyCopy } from "styles/Text"

const LanguageWrapper = styled(MD)`
  ${BodyCopy}:not(:last-child) {
    ${tw`mb-5`}
  }
`

export const Language = ({ children, unwrap = false, ...props }) => (
  <LanguageWrapper
    unwrapDisallowed={unwrap}
    disallowedTypes={unwrap ? ["paragraph"] : []}
    renderers={{ link: LFLRenderer, paragraph: BodyCopy }}
    {...props}
  >
    {children}
  </LanguageWrapper>
)

export default Language
